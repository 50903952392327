export const bbb = {
  sm: [
    // {
    //   name: '',
    //   desc: '加工款式',
    //   rul: ''
    // }
  ],
  bm: [
    {
      name: '格伯制版',
      desc: '格伯制版',
      url: require(`../../../assets/imgs/caijian/1.png`)
    },
    {
      name: '电脑排版',
      desc: '电脑排版',
      url: require('../../../assets/imgs/caijian/2.png')
    },
    {
      name: '超长裁剪2.6米',
      desc: '超长裁剪2.6米',
      url: require('../../../assets/imgs/caijian/3.jpg')
    },
    {
      name: '镂空裁',
      desc: '镂空裁剪',
      url: require('../../../assets/imgs/caijian/4.jpg')
    }
  ]
}

export const aaa = {
  sm: [
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    }
  ],
  bm: [
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    },
    {
      name: '',
      desc: '加工款式',
      rul: ''
    }
  ]
}
